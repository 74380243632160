class Subject<T>
{
  private iterator: number = 0;
  private value: T;
  private subscribers: { [id: string]: { (T: T): void }; } = {};

  constructor(value: T) {
    this.value = value
  }

  public next(value: T): void {
    this.value = value;
    let that = this;
    for(let i in this.subscribers){
      if (this.subscribers[i] != null)
        this.subscribers[i](that.value);
    }
  }

  public nextIf(value: T) {
    if (this.value != value) {
      this.next(value);
    }
  }

  public subscribe(fun: { (T: T): void; }, skipInitSubscription = false): string {
    this.iterator++;
    this.subscribers[this.iterator] = fun;
    if(!skipInitSubscription)
      fun(this.value);
    return this.iterator.toString();
  }

  public unsubscribe(id: string) {
    this.subscribers[id] = null;
  }

  public getValue(): T {
    return this.value;
  }
}

let acut = new Subject<string>("")